<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { useUserAgent } from '@/utilities/userAgent';
const { isSafari } = useUserAgent();

import { useAuthStore } from '@/stores/authStore';
import { useDeclarationUnit } from '@/stores/declarationUnit';
import { storeToRefs } from 'pinia';

const { t } = useI18n();
const authStore = useAuthStore();
const DUStore = useDeclarationUnit();
const { user } = storeToRefs(authStore);

const emit = defineEmits<{
    logOut: [];
}>();

</script>

<template>
    <div class="user-menu u-flex u-flex-column u-gap-2">
        <div class="user-menu__head u-flex u-gap-2" :class="{ 'd-block-safari': isSafari }">
            <i class="fa-solid fa-sharp fa-user"></i> <span class="unfocus-hide">{{ user.name }}</span>
        </div>
        <ul class="unfocus-hide" :class="{ 'd-block-safari': isSafari }">
            <template v-if="DUStore.activeDUID">
                <li>
                    <ArrowLink :to="{ name: 'users' }" :label="t('app.usermenu.users')"></ArrowLink>
                </li>
            </template>
            <li>
                <ArrowLink :label="t('app.usermenu.signout')" @click="() => emit('logOut')"> </ArrowLink>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/utilities" as u;

.user-menu {
    background-color: white;
    padding: 1rem;
    border-radius: 7px;
    z-index: 2;

    &:not(:focus-within, :hover) .unfocus-hide {
        // display: none;
        @include u.sr-only();
    }
}

/* Fix for safari showing the user menu inside the viewport */
.d-block-safari {
    display: --webkit-block !important;
    display: block !important;
}
</style> 